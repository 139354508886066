<template>
  <div>
    <div v-show="loading" class="div-print">
      <Loading :center="false" />
    </div>
    <div v-show="!loading">
      <!-- É renderizado o html abaixo para modificar as tabelas -->
      <div id="template-prod" v-show="false" v-html="template"></div>
      <div>

        <div class="side-by-side div-text">
          <InputText v-if="editMode" title="Nome (versão)" field="identification" :formName="formName" :required="true"
            :maxLength="100" v-model="documentVersion.identification" />
        </div>

        <Button v-show="editMode" _key="saveDocument" :disabled="!documentVersion.identification" color="white"
          backGroundColor="#94AA2A" title="Salvar" classIcon="fa-solid fa-floppy-disk" size="small"
          :clicked="saveDocument" />

        <Button v-show="editMode && !editModeDocumentVersion" _key="default" color="white" backGroundColor="#F0134D"
          title="Cancelar" classIcon="fa-solid fa-ban" size="small" :clicked="defaultMode" />

        <span class="div-pipe" v-show="editMode">
          <i class="fa-duotone fa-solid fa-pipe"></i>
        </span>

        <Button v-print="'#printMe'" _key="print" color="white" backGroundColor="#969799" title="Imprimir"
          classIcon="fas fa-print" size="small" :clicked="print" />

        <Button v-show="!editMode && canModifyDocument" _key="edit" color="white" backGroundColor="#156EBE"
          title="Alterar" classIcon="fa-solid fa-file-pen" size="small" :clicked="edit" />

        <Button v-show="rentId || invoiceId" _key="back" type="info" classIcon="fa-solid fa-arrow-left" size="small"
          :clicked="executeBack" />
      </div>
      <div v-if="!editModeDocumentVersion && editMode" class="title title-alert">
        As alterações feitas aqui são específicas para este documento e <b>NÃO</b> afetarão o modelo padrão!
      </div>
      <br />
      <ScrollBar :minHeight="400" :maxHeight="600">
        <div v-if="editMode">
          <div v-show="false" class="a4">
            <div id="printMe">
              <div :style="styleCss">
                <div v-html="documentHtmlFinal"></div>
              </div>
            </div>
          </div>
          <div>
            <DocumentEditor :editMode="true" />
          </div>
        </div>
        <div v-else class="a4">
          <div id="printMe">
            <div :style="styleCss">
              <DocumentPublic :template="documentPreview('prod')" :d="parameter" />
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  </div>
</template>
<script>
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import CodeEditor from "@nixweb/nixloc-ui/src/component/shared/CodeEditor.vue";
import DocumentEditor from "@nixweb/nixloc-ui/src/component/shared/DocumentEditor.vue";
import DocumentPublic from "@nixweb/nixloc-ui/src/component/shared/DocumentPublic";

import print from "vue-print-nb";

import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "DocumentPreview",
  directives: {
    print,
  },
  props: {
    documentId: String,
    rentId: String,
    periodRentId: String,
    movimentId: String,
    invoiceId: String,
    payableReceivableId: String,
    orderServiceId: String,
    canModifyDocument: {
      type: Boolean,
      default: false,
    },
    editModeDocumentVersion: {
      type: Boolean,
      default: false,
    },
    back: Function,
  },
  components: {
    DocumentEditor,
    DocumentPublic,
    CodeEditor,
    Molded,
    Loading,
    InputText,
    Alert,
    ScrollBar,
    Button,
  },
  data() {
    return {
      urlGetById: "/api/v1/generate-document/get-document",
      urlGetDocumentVersionById: "/api/v1/shared/document-version/get-by-id",
      urlCreateDocumentVersion: "/api/v1/shared/document-version/create",
      urlUpdateDocumentVersion: "/api/v1/shared/document-version/update",
      template: "",
      parameter: {},
      loading: true,
      styleCss: "",
      editMode: false,
      documentVersion: {
        id: "",
        identification: "",
        documentHtml: "",
        genericId: "",
      }
    };
  },
  mounted() {
    if (!this.editModeDocumentVersion) this.getDocumentById();
  },
  computed: {
    ...mapGetters("generic", ["documentPreview", "event"]),
    ...mapState("generic", ["documentHtml", "documentHtmlFinal"]),
    documentHtml: {
      get() {
        return this.$store.state.generic.documentHtml;
      },
      set(value) {
        this.updateDocumentHtml(value);
      },
    },
  },
  methods: {
    ...mapActions("generic", ["getApi", "postApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "updateDocumentHtml", "updateDocumentHtmlFinal", "removeLoading"]),
    getDocumentById() {
      this.loading = true;
      let params = {
        url: this.urlGetById,
        obj: {
          documentId: this.documentId,
          rentId: this.rentId,
          periodRentId: this.periodRentId,
          movimentId: this.movimentId,
          invoiceId: this.invoiceId,
          payableReceivableId: this.payableReceivableId,
          orderServiceId: this.orderServiceId,
        },
      };
      this.getApi(params).then((response) => {
        this.styleCss = response.content.styleCss;
        this.parameter = response.content.parameter;
        this.template = response.content.documentHtml;

        let self = this;
        setTimeout(function () {
          self.updateDocumentHtml(response.content.documentHtml);
          self.template = self.documentPreview;
          self.loading = false;
        }, 300);
      });
    },
    getDocumentVersionById() {
      this.loading = true;
      let params = {
        url: this.urlGetDocumentVersionById,
        obj: {
          documentId: this.documentVersion.id,
        },
      };
      this.getApi(params).then((response) => {
        let self = this;
        setTimeout(function () {
          self.updateDocumentHtmlFinal(response.content.documentHtml);
          self.documentVersion.id = response.content.id;
          self.documentVersion.identification = response.content.identification;
          self.loading = false;
          self.editMode = true;
        }, 300);
      });
    },
    saveDocument() {
      this.documentVersion.documentHtml = this.documentHtmlFinal;
      this.documentVersion.genericId = this.rentId;
      if (this.documentVersion.id) {
        let params = { url: this.urlUpdateDocumentVersion, obj: this.documentVersion };
        this.putApi(params).then((response) => {
          this.removeLoading(["saveDocument"]);
        });
      } else {
        let params = { url: this.urlCreateDocumentVersion, obj: this.documentVersion };
        this.postApi(params).then((response) => {
          this.documentVersion.id = response.content.id;
          this.removeLoading(["saveDocument"]);
        });
      }
    },
    print() {
      this.removeLoading(["print"]);
    },
    executeBack() {
      if (this.back) this.back();
    },
    edit() {
      this.addEvent({ name: "updateDocumentHtml" });
      let self = this;
      setTimeout(function () {
        self.editMode = true;
        self.removeLoading(["edit"]);
      }, 200);

    },
    defaultMode() {
      let self = this;
      setTimeout(function () {
        self.getDocumentById();
        self.editMode = false;
        self.removeLoading(["default"]);
      }, 200);
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "documentVersionUpdate") {
          this.documentVersion.id = event.data.id;
          this.getDocumentVersionById();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.a4 {
  max-width: 1140px;
  min-height: 21cm;
  padding: 20px;
  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;
  box-shadow: 0 0 5px hsl(0deg 0% 0% / 10%);
  margin: 0 auto;
}

.div-print {
  height: 660px;
}

.div-save-cancel {
  margin-top: 35px;
}

.title-alert {
  font-size: 13px;
  margin-top: -15px;
  color: rgb(92, 92, 92);
  font-style: italic;
}

.div-pipe {
  margin-left: 5px;
}

.div-text {
  width: 450px;
}
</style>
